<template>
  <div>
    <query-form class="content_block" label-width="110px" @search="handleSearch" @reset="handleReset">
      <el-form-item label="仓库：" prop="warehouseId">
        <el-select v-model="form.warehouseId">
          <el-option :value="undefined" label="全部">全部</el-option>
          <el-option v-for="item in warehouseSearchList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备件名称：" prop="sparePartName">
        <el-input v-model="form.sparePartName" placeholder="请输入备件名称"></el-input>
      </el-form-item>
      <el-form-item label="备件编码：" prop="sparePartCode">
        <el-input v-model="form.sparePartCode" placeholder="请输入备件编码"></el-input>
      </el-form-item>
      <el-form-item label="出入库类型：" prop="type">
        <el-select v-model="form.type">
          <el-option :value="undefined" label="全部">全部</el-option>
          <el-option
            v-for="item in INBOUND_OUTBOUND_TYPE"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="customerList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { getStockInOutList, getWarehouseList } from '@/api/stock.js';
import { INBOUND_OUTBOUND_TYPE } from '../stockType.js';
import { dateFormat } from '@/utils/dateFormat.js';

export default {
  name: 'STOCKIN_RECORD',
  mixins: [TablePaginationMixin],
  data () {
    return {
      INBOUND_OUTBOUND_TYPE,
      warehouseSearchList: [],
      form: {
        warehouseId: undefined,
        sparePartName: '',
        sparePartCode: '',
        type: undefined,
      },
      customerList: [],
      columnList: [
        {
          tooltip: true,
          label: '出入库单号',
          minWidth: '180px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '备件名称',
          minWidth: '180px',
          prop: 'sparePartName',
        },
        {
          tooltip: true,
          label: '备件编码',
          minWidth: '180px',
          prop: 'sparePartCode',
        },
        {
          tooltip: true,
          label: '备件类型',
          minWidth: '180px',
          prop: 'sparePartTypeName',
        },
        {
          tooltip: true,
          label: '规格型号',
          minWidth: '180px',
          prop: 'spec',
        },
        {
          tooltip: true,
          label: '出入库类型',
          minWidth: '180px',
          prop: 'type',
          format: (val) => val && INBOUND_OUTBOUND_TYPE.find((i) => i.value === val)?.label,
        },
        {
          tooltip: true,
          label: '出入库数量',
          minWidth: '180px',
          prop: 'quantity',
        },
        {
          tooltip: true,
          label: '仓库',
          minWidth: '180px',
          prop: 'warehouseName',
        },
        {
          tooltip: true,
          label: '库存结余',
          minWidth: '180px',
          prop: 'inventoryQuantity',
        },
        {
          tooltip: true,
          label: '原仓库',
          minWidth: '180px',
          prop: 'oldWarehouseName',
        },
        {
          tooltip: true,
          label: '目标仓库',
          minWidth: '180px',
          prop: 'newWarehouseName',
        },
        {
          tooltip: true,
          label: '发起人',
          minWidth: '180px',
          prop: 'launchUserName',
        },
        {
          tooltip: true,
          label: '办理人',
          minWidth: '180px',
          prop: 'handleUserName',
        },
        {
          tooltip: true,
          label: '备注',
          minWidth: '180px',
          prop: 'remark',
        },
        {
          tooltip: true,
          label: '时间',
          minWidth: '180px',
          prop: 'createTime',
          format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
      ],
    };
  },
  created () {
    getWarehouseList().then((rs) => {
      this.warehouseSearchList = rs?.body?.list;
    });
  },
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      Object.keys(this.form).forEach((i) => {
        this.form[i] = undefined;
      });
      this.handleSearch();
    },
    async getTableList () {
      const param = {
        ...this.form,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };
      const rs = await getStockInOutList(param);
      if (rs.heads.code === 200) {
        const { list, total } = rs.body;
        this.customerList = list;
        this.pagination.total = total;
      }
    },
  },
};
</script>
